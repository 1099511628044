import { useGlobalContext } from '../../context/context';

const Wallet = () => {
  const { userDetails } = useGlobalContext();

  const {
    pendBalance,
    referralBonus,
    tradingProgress,
    totalDeposit,
    totalEquity,
    tradeProfit,
  } = userDetails;

  return (
    <article className='wallet'>
      <div className='box'>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <h3>Trade Profit</h3>
        <p className='green'>
          <span>&#36;</span>
          {tradeProfit}
          <span className='span2'>.00</span>
        </p>
      </div>
      <div className='box'>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <h3>Total Deposits</h3>
        <p>
          <span>&#36;</span>
          {totalDeposit}
          <span className='span2'>{`${totalDeposit ? `.63` : `.00`}`}</span>
        </p>
      </div>
      <div className='box'>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <h3>Pend. Balance</h3>
        <p>
          <span>&#36;</span>
          {pendBalance}
          <span className='span2'>{`${pendBalance ? `.59` : `.00`}`}</span>
        </p>
      </div>
      <div className='box'>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <h3>Referral Bonus</h3>
        <p>
          <span>&#36;</span>
          {referralBonus}
          <span className='span2'>{`${referralBonus ? `.47` : `.00`}`}</span>
        </p>
      </div>
      <div className='box'>
        <h3>Total Equity</h3>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <p>
          <span>&#36;</span>
          {totalEquity}
          <span className='span2'>.00</span>
        </p>
      </div>
      <div className='box'>
        <img
          className='card-bg'
          src='/android-chrome-192x192.png'
          alt='logo'
          width={50}
          height={60}
        />
        <h3>Signal Strength</h3>
        <p>
          {tradingProgress}
          <span className='span2'>{`${
            tradingProgress ? `.99%` : `.00%`
          }`}</span>
        </p>
      </div>
    </article>
  );
};

export default Wallet;
